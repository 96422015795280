@import "normalize.css";
@import "primereact/resources/themes/saga-blue/theme";
@import "primereact/resources/primereact";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex";
@import "./_variable.scss";

html {
	font-size: 10px;
	// #popup_menu {
	// 	left: 1090px !important;
	// 	top: 55px !important;
	// }
	body {
		margin: 0;
		padding: 0;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
			"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
			"Helvetica Neue", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 10px;
		color: rgb(31, 31, 31);
	}

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
			monospace;
	}

	.vw-input-border {
		border-color: var(--color-border) !important;
	}
	input {
		height: 4rem;
		font-size: 1.4rem;
		&:focus,
		&:hover {
			box-shadow: 0 0 0 1.5px #fff !important;
			border-color: var(--color-warning) !important;
		}
		&.p-disabled {
			background: var(--color-dark-grey);
			font-weight: 500 !important;
		}
		&:disabled {
			color: #000;
		}
	}
	.p-component {
		font-size: 1.2rem;
	}
	.p-float-label label {
		// transform: translate(-50%);
		// margin: 0 !important;
	}
	.form-element {
		width: 100% !important;
		padding-top: 1.6rem;
		font-size: 1.4rem;
	}
	.form-element-nfl {
		width: 100% !important;
		padding-top: 0.6rem;
		font-size: 1.2rem;
	}
	.form-element-label {
		&.p-inputtext ~ label {
			left: 0;
			padding-left: 1rem;
		}
		&:focus ~ label,
		&.p-filled ~ label {
			top: 1rem !important;
			font-size: 1.2rem;
		}
		&.p-inputtext:enabled {
			&:focus,
			&:hover {
				box-shadow: 0 0 0 0.2rem #fff !important;
				border-color: var(--color-warning) !important;
			}
		}
	}
	.input-addon {
		// border-right: 1px solid #ced4da;
		border: none;
		background-color: var(--color-dark-grey);
		min-width: 4rem;
		font-size: 1.4rem;
		margin-right: 0.5rem;
	}
	.vw-input {
		padding-left: 1rem;
		padding-right: 1rem;
		@extend .form-element;
		@extend .form-element-label;
		&.p-disabled {
			background: var(--color-dark-grey);
		}
	}

	.vw-input-password {
		& ~ label {
			left: 0;
			padding-left: 1rem;
		}
		& ~ label,
		& ~ label {
			top: 1rem !important;
			font-size: 1.2rem;
		}
		i {
			right: 1.5rem !important;
			font-size: 1.4rem;
		}
		&:enabled {
			&:focus,
			&:hover {
				box-shadow: 0 0 0 0.2rem #fff !important;
				border-color: var(--color-warning) !important;
			}
		}
		width: 100%;
		input {
			width: 100%;
			padding-top: 1.5rem;
			padding-left: 1rem;
		}
	}

	.vw-input-nfl {
		padding-left: 1rem;
		padding-right: 1rem;
		@extend .form-element-nfl;
		@extend .form-element-label;
		&.p-disabled {
			background: var(--color-dark-grey);
		}
	}
	.vw-input-nfl-border {
		@extend .vw-input-nfl;
		&.p-inputtext, input {
			border-color: #ced4da;
		}
		
	}

	.vw-dropdown {
		height: 4rem;
		text-align: left;
	}

	.vw-dropdown {
		@extend .form-element;
		&.p-disabled {
			background: #8080801f;
		}
		&.p-focus,
		&:hover {
			box-shadow: 0 0 0 0.2rem #fff !important;
			border-color: var(--color-warning) !important;
		}
		&.p-inputwrapper-focus ~ label,
		&.p-inputwrapper-filled ~ label {
			top: 1rem;
			font-size: 1.2rem;
		}
		.p-dropdown-trigger,
		.p-multiselect-trigger {
			width: 4rem;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			.pi {
				font-size: 1.5rem;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #fff;
			}
		}
		&:focus {
			border-color: var(--color-warning);
		}
		.p-dropdown-item {
			font-size: 1.2rem;
		}
		.p-inputtext {
			padding-left: 1rem;
			font-size: 1.4rem;
		}
		&.p-dropdown ~ label,
		&.p-multiselect ~ label {
			padding-left: 1rem;
			left: 0;
		}
	}

	.vw-dropdown-with-border {
		border-color:#ced4da;
		@extend .form-element-nfl;
	}

	.vw-radio-group {
		margin: 0;
		font-size: 1.4rem;
		.vw-radio-button {
			margin-right: 1.5rem;
			display: flex;
			align-items: center;
			.p-radiobutton {
				margin-right: 0.5rem;
			}
		}
		.vw-label {
			margin-right: 2rem !important;
			margin-bottom: 0;
		}
	}

	.vw-radio-button {
		.p-radiobutton-box {
			border-width: 1px;
			&.p-highlight:not(.p-disabled).p-focus {
				box-shadow: none;
			}
			&.p-highlight,
			&.p-highlight:not(.p-disabled):hover {
				border-color: #00000073;
				background: #fff;
			}
			.p-radiobutton-icon {
				background-color: #000;
				height: 6px;
				width: 6px;
			}
			&:not(.p-disabled):not(.p-highlight):hover {
				border-color: #00000073;
			}
		}
	}
	.vw-inputnumber-with-border {
		input {
			border-color: #ced4da;
			padding: 0.5rem 0.8rem;
		}
	}
	.p-card .p-card-title {
		margin-top: 1rem;
		margin-bottom: 0rem;
	}
	.p-card .p-card-footer {
		padding: 1rem 0;
	}
	.p-button-lg {
		padding: 1rem 1.5rem !important;
		min-width: 20%;
	}

	.min-width {
		min-width: 30%;
	}

	.p-button.p-button-warning,
	.p-button.p-button-warning:enabled:hover {
		background: var(--color-warning);
		border: 1px solid var(--color-warning);
		color: #fff;
	}

    .p-button.p-button-orange,
	.p-button.p-button-orange:enabled:hover {
		background: var(--color-orange);
		border: 1px solid var(--color-orange);
		color: #fff;
	}

    .vw-calendar {
        span {
            width: 100%;
        }
        input {
            @extend .vw-input-border;
            padding-left: 1rem;
            @extend .form-element
        }
        .p-calendar ~ label {
            left: 0;
            padding-left: 1rem;
            font-size: 1.2rem;
            top: 1rem;
        }
        .p-calendar-disabled {
            background-color: #8080801f;
        }
        .p-inputwrapper-focus ~ label, .p-inputwrapper-filled ~ label {
            top: 1rem;
            font-size: 1.2rem;
        }
        input.p-inputtext:enabled {
            &:focus, &:hover {
                box-shadow: 0 0 0 0.2rem #fff;
                border-color: inherit;
            }
        }
        .p-datepicker-trigger {
            width: 4rem;
            background-color: #fff;
            color: #000;
            border-color: #808080;
            border-left: none;
            .pi {
                font-size: 1.5rem;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }


	.App-container {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
        background-color: var(--color-light-grey);
		.container-fluid {
			// background-color: var(--color-light-grey);
			// max-width: 1820px;
			width: 100%;
			// padding: 0 10%;
			.child-outlet {
				// background-color: var(--color-light-grey);
				padding: 1% 0;
				min-height: 60vh;
			}
		}
		.center-align {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			min-height: 40vh;
		}
		.bg-blue {
			.p-panel-header {
				background: var(--color-light-blue);
				color: #fff;
			}
		}
	}
	label {
		color: #333;
		vertical-align: middle;
		display: inline-block;
		// margin-top: 0.8rem;
	}

	.p-dialog-header {
		background: var(--color-warning) !important;
		color: #fff !important;
		.p-dialog-header-icon {
			color: #fff !important;
			.pi {
				font-size: 1.4rem;
			}
		}
		.p-dialog-header-icon:focus {
			box-shadow: none !important;
		}
		.p-dialog-header-icon:enabled:hover {
			background: transparent !important;
		}
	}

	.pencil-icon {
		top: 1.5rem;
		left: 1rem;
		color: var(--color-warning);
		.pi {
			font-size: 1.7rem;
		}
	}

	.p-dialog .p-dialog-content {
		padding-bottom: 0 !important;
		padding-top: 0.5rem !important;
	}

	.p-dialog .p-dialog-footer {
		text-align: left;
	}

	.p-card .p-card-body {
		padding: 2rem;
	}

	a {
		color: var(--color-warning);
		font-size: 1.4rem;
		cursor: pointer;
	}

	.p-tabview .p-tabview-nav {
		justify-content: space-around;
	}

	.p-tabview .p-tabview-nav li {
		min-width: 24%;
		text-align: center;
	}

	.p-tabview .p-highlight .p-tabview-nav-link {
		background: #fff;
		color: #333 !important;
		border-color: var(--color-warning) !important;
		display: flex;
		justify-content: center;
		&:focus {
			box-shadow: none !important;
		}
		&:hover {
			// background: var(--color-warning) !important;
			border-color: var(--color-warning) !important;
		}
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		// background: var(--color-warning);
		// color: #fff;
		display: flex;
		justify-content: center;
		&:focus {
			box-shadow: none !important;
		}
		&:hover {
			// background: var(--color-warning) !important;
			border-color: var(--color-warning) !important;
		}
	}

	.p-slider {
		background-color: var(--color-dark-grey);
	}

	.p-slider .p-slider-range {
		background: var(--color-warning);
	}

	.p-slider-handle {
		border-color: var(--color-warning) !important;
		background-color: var(--color-warning) !important;
	}

	.align-text {
		text-align: right;
	}

	.w-100 {
		width: 100% !important;
	}

	.add-button {
		display: flex !important;
		justify-content: right !important;
		margin-right: 1rem !important;
	}

	.table-demo {
		.p-progressbar {
			height: 0.5rem;
			background-color: #d8dadc;
	
			.p-progressbar-value {
				background-color: #607d8b;
			}
		}
	
		.p-datatable .p-column-filter {
			display: none;
		}
	
		.table-header {
			// font-size: 1.25rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			p {
				margin-left: 1rem;
			}
			span {
				margin-right: 13rem;
				input {
					width: 120%;
					height: 3rem;
					padding-left: 2.5rem;
				}
			}
		}

		.p-datatable-header {
			background-color: var(--color-warning);
			color: #fff;
			
		}
	
		.p-datatable.p-datatable-customers {
			.p-datatable-header {
				padding: 0.5rem;
				text-align: left;
				
				// font-size: 1.5rem;
			}
	
			.p-paginator {
				padding: 1rem;
				justify-content: right;
			}
	
			.p-datatable-thead  {
				tr {
					height: 5rem;
					th {
						text-align: left;
					}
				}
			}

			.p-datatable-tbody {
				font-size: 1.3rem;
			}
	
			.p-datatable-tbody > tr > td {
				cursor: auto;
				padding: 1rem 0.5rem !important;
				// border-width: 0 !important;
			}
	
			.p-dropdown-label:not(.p-placeholder) {
				text-transform: uppercase;
			}
		}
	
		/* Responsive */
		.p-datatable-customers .p-datatable-tbody > tr > td > .p-column-title {
			display: none;
		}
	
		// .customer-badge {
		// 	border-radius: 2px;
		// 	padding: 0.25em 0.5rem;
		// 	text-transform: uppercase;
		// 	font-weight: 700;
		// 	font-size: 12px;
		// 	letter-spacing: 0.3px;
	
		// 	&.status-qualified {
		// 		background: #c8e6c9;
		// 		color: #256029;
		// 	}
	
		// 	&.status-unqualified {
		// 		background: #ffcdd2;
		// 		color: #c63737;
		// 	}
	
		// 	&.status-negotiation {
		// 		background: #feedaf;
		// 		color: #8a5340;
		// 	}
	
		// 	&.status-new {
		// 		background: #b3e5fc;
		// 		color: #23547b;
		// 	}
	
		// 	&.status-renewal {
		// 		background: #eccfff;
		// 		color: #694382;
		// 	}
	
		// 	&.status-proposal {
		// 		background: #ffd8b2;
		// 		color: #805b36;
		// 	}
		// }
	
		.p-progressbar-value.ui-widget-header {
			background: #607d8b;
		}
		@media only screen and (min-width: 768px) and (max-width: 1024px) {
			
			.p-datatable-customers .p-datatable-tbody > tr > td > .p-column-title {
				display: none!important;
			}
			
		}

		@media (max-width: 640px) {
			.p-progressbar {
				margin-top: 0.5rem;
			}
		}
	
		
	
		@media screen and (max-width: 960px) {

			.p-datatable {
				&.p-datatable-customers {
					.p-datatable-thead > tr > th,
					.p-datatable-tfoot > tr > td {
						//display: none !important;
					}
	
					.p-datatable-tbody > tr {
						> td {
							text-align: left;
							display: block;
							border: 0 none !important;
							width: 100% !important;
							float: left;
							clear: left;
							border: 0 none;
							padding: 1rem 0.5rem !important;
							.p-column-title {
								padding: 0.4rem;
								min-width: 30%;
								display: inline-block;
								margin: -0.4rem 1rem -0.4rem -0.4rem;
								font-weight: bold;
							}
	
							.p-progressbar {
								margin-top: 0.5rem;
							}
						}
					}
	
					colgroup {
						display: none;
					}
				}
	
				.p-datatable-header {
					.table-header {
						flex-direction: column !important;
						
					}
				}
			}
		}
	}

	.Reports {
		.p-datatable-thead > tr > th {
			text-align: center !important;
		}
		.p-datatable-tbody > tr > td {
			text-align: center !important;
		}
	}
	
	.RegisterForm {
		padding: 2rem;
		width: 70%;
		margin: auto;
		
		.p-card-title, .p-card-subtitle {
			font-weight: 500;
			margin-left: 1rem;
		}
        .p-card-subtitle {
            margin-top: 0.5rem;
        }
		.content {
			> div {
				margin: 1rem 0;
			}
			> div:last-child {
				margin-bottom: 0;
			}
		}
	
	}

	.printLayout {
		height: 3rem !important;
		input {
			height: 3rem !important;
		}
	}

	.fileUpload {
		.p-button {
			background: var(--color-warning);
			border: 1px solid var(--color-warning);
		}
	}
	
	.ipadGrid {
		overflow: auto;
	}
	

	@media (min-width: 1200px) {
		.App-container {
			.container-fluid {
				width: 100%;
			}
			.outer-container {
				margin: 0px auto;
                width: 95%;
			}
		}
	}

	@media (min-width: 992px) {
		.container-fluid {
			width: 970px;
		}
	}

	@media (min-width: 768px) {
		.container-fluid {
			width: 750px;
		}
	}
	@media only screen and (min-width: 768px) and (max-width: 1024px) {
		.ipadGrid { // added vertical scroll bar for ipad
			height: 40rem;
			overflow-y: auto;
			overflow-x: auto;
		}
		.p-tabview .p-tabview-nav li {
			min-width: 18%;
		}
		.align-text {
			text-align: left;
		}
	}	
	@media (min-width: 1024px) {
		.align-text {
			text-align: right;
		}
	}
	@media only screen and (min-width: 820px) and (max-width: 1180px) {
		.ipadGrid { // added vertical scroll bar for ipad
			height: 40rem;
			overflow-y: auto;
	 		overflow-x: auto;
		}
	}	

}
.BarChartModal {
    .p-dialog-content {
        padding: 0 !important;
    }
    .barChart {
        canvas {
            background: url("https://www.compass.com/ucfe-assets/homepage/homepage-v1.34.0/assets/hero_high_res.jpeg");
            background-size: cover;
        }
    }
}

.message_info {
    width: 100%;
    padding: 2rem;
    background-color: #026263 !important;
    justify-content: start;
    span {
        color: #fff !important;
        font-size: 1.6rem !important;
        a {
            color: #fff !important;
        }
    }
}

.message_warning {
    width: 100%;
    padding: 2rem;
    justify-content: start;
    span {
        font-size: 1.6rem !important;
       
    }
}

.p-datatable .p-datatable-thead > tr > th  {
    background-color: #cce8fe !important;
}

.blink {
    animation: blink 1s steps(1, end) infinite;
    color:red
  }
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .pdf-container {
    width: 100%; /* Adjust width as needed */
    height: 400px; /* Adjust height as needed */
    overflow: scroll;
  }