.loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .p-progress-spinner {
        z-index: 9999;
        height: 5rem;
        width: 5rem;
        position: absolute;
        top: 50%;
    }
}
