.Header {
    height: 12vh;
    display: flex;
    font-size: 1.4rem;
    // border: 1px solid #e3e3e3;
    margin: 0 auto;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;

        img {
            height: 5rem;
        }

        .logo_title {
            margin-left: 2rem;

            h1 {
                font-size: 23px;
                padding-top: 10px;
                color: #026263;
                font-weight: 600;
                text-decoration: none ! important;
                margin-bottom: 0;
            }

            h4 {
                font-size: 17px;
                margin-top: 0;
                /* margin-bottom: 3px; */
                color: #000000;
                font-weight: 500;
                text-decoration: none
            }
        }
    }

    .main {
        display: flex;
    }

    .right {
        justify-content: center;
        align-items: center;
        display: flex;

        .quote {
            font-weight: 700;
            color: #3535f3;
        }

        .naturopath {
            font-weight: 700;
            color: #3535f3;
        }
        img {
            height: 9rem;
        }
        h4,
        i {
            font-weight: 500;
        }
    }
}
.header-card {
    .p-card-body {
        padding: 0 5rem !important;
    }
}